import React, { useState } from 'react';

import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';

import { Replay } from 'vimond-replay';
import 'vimond-replay/index.css';
import ShakaVideoStreamer from 'vimond-replay/video-streamer/shaka-player';
import Playlist from "./tvplus.json"
const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const replayOptions = {
  videoStreamer: {
    shakaPlayer: {
      allowCrossSiteCredentials:true,
      requestFilter: (type, request) => {
            console.log(request);
        if (type === 1 || type === 0) { // shaka.net.NetworkingEngine.RequestType.MANIFEST || .SEGMENT
  
          request.headers = {
            "accept": "*/*",
            "accept-language": "tr,en;q=0.9,ru;q=0.8,en-US;q=0.7,tr-TR;q=0.6",
            "sec-ch-ua": "\"Google Chrome\";v=\"93\", \" Not;A Brand\";v=\"99\", \"Chromium\";v=\"93\"",
            "sec-ch-ua-mobile": "?0",
            "sec-ch-ua-platform": "\"Linux\"",
            "sec-fetch-dest": "empty",
            "sec-fetch-mode": "cors",
            "sec-fetch-site": "same-site"
          }
        }
      },
    customConfiguration: {
          bufferingGoal: 20

      }
    }
  }
};


function Player() {
  const [source, setSource] = useState({
            streamUrl: 'https://izmottrrs.tvplus.com.tr:443/PLTV/88888888/224/3221229536/3221229536.mpd?rrsip=izmottrrs.tvplus.com.tr:443&zoneoffset=0&devkbps=4500-6500&servicetype=2&accounttype=1&limitflux=-1&limitdur=-1&tenantId=9001',
            contentType: 'application/dash+xml',
            licenseUrl: 'https://cors.faceittracker.net/https://widevine.tvplus.com.tr:8063/?deviceId=N2VkNzljMDgtOWUyMi0zYzliLThiODItZmUyMjI1ZDM1OGE5',
                drmType: 'com.widevine.alpha',
          });

  return (
    <div className="App">

<Box sx={{ flexGrow: 1 }}>
      <Grid container>
  <Grid item xs={2} spacing={0} style={{maxHeight: '100vh', overflow: 'auto', padding:0,margin:0}}>
{Playlist.channellist.map(p=><Item> <Button variant="outlined" fullWidth onClick={() => {
    setSource({
            streamUrl: "https://cors.faceittracker.net/"+p.playurl,
         contentType: 'application/dash+xml',
            licenseUrl: 'https://cors.faceittracker.net/https://widevine.tvplus.com.tr:8063/?deviceId=N2VkNzljMDgtOWUyMi0zYzliLThiODItZmUyMjI1ZDM1OGE5',
                drmType: 'com.widevine.alpha',
          });
  }}>{p.name}</Button></Item>)}
        </Grid>
   <Grid item xs={10}>

          <Item>
<Replay 
source={source}
    options={replayOptions}

>
  <ShakaVideoStreamer />
</Replay>

          </Item>
        </Grid>
      </Grid>
    </Box>


    </div>
  );
}

export default Player;
